import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/Layout";
import formatContentfulRichText from "@v4/talend/src/utils/formatContentfulRichText";
import { getLangPrefixedPath } from "@v4/utils/pathPrefix";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { isTabletQuery } from "../../utils/mediaQueries";

import { singleBlogEntryItemTranslations } from "../../translations/blog";

// Styling
import * as blogStyles from "./blogSingleEntryPage.module.css";
import classNames from "classnames/bind";

import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import SocialSharing from "../../components/socialSharing/SocialSharing";
import Clamp from "react-multiline-clamp";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import SEO from "@v4/gatsby-theme-talend/src/components/Seo/Seo";

export const query = graphql`
    query($id: String!, $parentCategoryId: String, $locale: String = "en") {
        contentfulBlog(id: { eq: $id }) {
            languages
            blogText {
                raw
                references {
                    ...RTEReferences
                }
            }
            previewText
            category {
                name
                slug
                title
            }
            author {
                name
                slug
            }
            image {
                # fluid (maxWidth: 900, transformations: "c_fit")
                public_id
                width
                height
                format
            }
            publishDate
            updatedAt
            metaFields {
                metaTitle
                metaDescription
                canonicalUrlOverride
                metaImage {
                    # fluid (maxWidth: 900, transformations: "c_fit")
                    original_secure_url
                    public_id
                    width
                    height
                    format
                }
            }
            imageAltText
        }
        moreBlogs: allContentfulBlog(
            sort: { fields: createdAt, order: DESC }
            limit: 3
            filter: {
                category: { elemMatch: { id: { eq: $parentCategoryId } } }
                node_locale: { eq: $locale }
                id: { ne: $id }
            }
        ) {
            nodes {
                id
                title
                slug
                node_locale
            }
        }
        contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
        footerNav: contentfulMenu(name: { eq: "Footer Nav" }, node_locale: { eq: $locale }) {
            ...FooterNav
        }
        footerSocials: contentfulMenu(name: { eq: "Footer Socials" }, node_locale: { eq: $locale }) {
            ...FooterSocials
        }
        footerSubMenu: contentfulMenu(name: { eq: "Footer Sub Menu" }, node_locale: { eq: $locale }) {
            ...FooterSubMenu
        }
    }
`;

export default function BlogSingleEntryPage({ data, pageContext }) {
    const { blogData, locale } = pageContext;
    const lang = locale;
    const blog = { ...blogData, ...data.contentfulBlog };
    const footerData = {
        footerNav: data.footerNav,
        footerSocials: data.footerSocials,
        footerSubMenu: data.footerSubMenu,
    };

    const styleProps = {};
    const cx = classNames.bind(blogStyles);
    const isTablet = useMediaQuery(isTabletQuery, undefined);

    // check if there is a blog image available
    const blogImage = blog.image ? blog.image[0] : false;
    const blogImageStyle = blogImage
        ? {
              width: isTablet ? "75%" : "100%",
              height: "auto",
          }
        : null;
    const blogImageAltText = blog.imageAltText ?? "";
    const blogHeaderVariantClassName = blogImage ? "" : "noImage";
    const categoryText = blog.category ? blog.category[0]?.title : "";
    const categoryPath = categoryText ? `/blog/${blog.category[0]?.slug}` : null;

    // This function generates the author text
    const getAuthorText = () => {
        // The localised string for "By"
        const authorPrefix = `${singleBlogEntryItemTranslations[lang].authorTextPrefix} `;

        // No check if there are any authors, and also possibly iterate over multiple authors
        // If no authors, then the author is "Talend"
        if (!blog.author) return <h5 className={cx("blogAuthor")}>{`${authorPrefix} Talend`}</h5>;

        // 2 author = [author] & [author]
        // 2+ authors = [author], [author], ... & [author]
        return (
            <h5 className={cx("blogAuthor")}>
                {authorPrefix}
                {blog.author.map((a, i, arr) => (
                    <React.Fragment key={i}>
                        <Link to={getLangPrefixedPath(`/blog/${a.slug}`, lang)} className={cx("authorLink")}>
                            {a.name}
                        </Link>
                        {i < arr.length - 1 ? (arr.length <= 2 ? " & " : i === 0 ? ", " : " & ") : ""}
                    </React.Fragment>
                ))}
            </h5>
        );
    };

    // Set up breadcrumbs object
    // If the category is null, then omit it from the breadcrumb.
    let crumbList = categoryText
        ? {
              [singleBlogEntryItemTranslations[lang].blogName]: "/blog",
              [categoryText]: categoryPath,
              [blog.title]: null,
          }
        : {
              [singleBlogEntryItemTranslations[lang].blogName]: "/blog",
              [blog.title]: null,
          };

    return (
        <Layout
            headerMenu={data.contentfulMenu}
            footerMenu={footerData}
            notificationBar={false}
            pageStyleProps={styleProps}
        >
            <SEO
                title={blog.metaFields?.metaTitle ?? blog.title}
                pathname={getLangPrefixedPath(`/blog/${blog.slug}`, blog.node_locale)}
                description={blog.metaFields?.metaDescription ?? blog.previewText}
                modifiedTime={blog.updatedAt}
                createdTime={blog.publishDate}
                metaImage={blog.metaFields?.metaImage ?? null}
                canonicalUrlOverride={blog.metaFields?.canonicalUrlOverride}
                // Blogs are currently in Engilsh-only, as of Dec 2021
                languages={["English"]}
                type={`BlogPost`}
            />
            <Helmet bodyAttributes={{ class: "darkOpaque" }} />
            <section className={cx("blogHeader")}>
                <div className={cx("gridWrapper", blogHeaderVariantClassName)}>
                    <div className={cx("contentWrap")}>
                        <h1 className={cx("blogTitle")}>{blog.title}</h1>
                        {getAuthorText()}
                        <SocialSharing
                            title={`${blog.title} - Read more: `}
                            slug={blog.slug}
                            contentType={`ContentfulBlog`}
                            locale={blog.node_locale}
                        />
                    </div>
                    {blogImage && (
                        <div className={cx("imageWrap")}>
                            <Image
                                className={cx("blogEntryImage")}
                                image={blogImage}
                                style={blogImageStyle}
                                alt={blogImageAltText}
                            />
                        </div>
                    )}
                </div>
            </section>
            <section className={cx("blogWrapper")}>
                <Breadcrumbs crumbList={crumbList} />
                <div className={cx("blogContentWrapper")}>
                    {data.moreBlogs.nodes.length > 0 && (
                        <div className={cx("moreBox")}>
                            <h5>{`${singleBlogEntryItemTranslations[lang].more} ${categoryText.toLowerCase()}`}</h5>
                            <ul>
                                {data.moreBlogs.nodes.map((blog) => (
                                    <li key={blog.id}>
                                        <Link
                                            to={getLangPrefixedPath(`/blog/${blog.slug}`, blog.node_locale)}
                                            className={cx("blogLink")}
                                        >
                                            <Clamp lines="2" withTooltip={false}>
                                                {blog.title}
                                            </Clamp>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <div className={cx("blogContent", "pageWithSideBar")}>
                        {blog.blogText?.raw && renderRichText(blog.blogText, formatContentfulRichText())}
                    </div>
                </div>
            </section>
        </Layout>
    );
}
