// extracted by mini-css-extract-plugin
export var authorLink = "blogSingleEntryPage-module--authorLink--23989";
export var blogAuthor = "blogSingleEntryPage-module--blogAuthor--9212b";
export var blogContent = "blogSingleEntryPage-module--blogContent--931fc";
export var blogContentWrapper = "blogSingleEntryPage-module--blogContentWrapper--55ec1";
export var blogHeader = "blogSingleEntryPage-module--blogHeader--780af";
export var blogLink = "blogSingleEntryPage-module--blogLink--e6ab8";
export var blogTitle = "blogSingleEntryPage-module--blogTitle--5f31c";
export var blogWrapper = "blogSingleEntryPage-module--blogWrapper--a6c20";
export var gridWrapper = "blogSingleEntryPage-module--gridWrapper--48a2c";
export var moreBox = "blogSingleEntryPage-module--moreBox--7730d";
export var noImage = "blogSingleEntryPage-module--noImage--612e0";